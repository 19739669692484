<template>
  <div>
    <v-row class="neumorphism-flat px-5 pb-5 topic-analysis-card">
      <v-col cols="12" class="pb-0">
        <div class="topic-analysis-card-title ">Topic Analysis:</div>
      </v-col>
      <template v-if="!is_content_loading">
        <v-col
          v-if="donut_chart_data"
          cols="12"
          class="d-flex justify-end align-center py-0"
        >
          <div id="chart" class="pa-0 mt-3">
            <apexchart
              type="donut"
              width="320"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </v-col>
        <div v-else class="topic-analysis-card-sub-title py-5 pl-5">
          No Data Available
        </div>
      </template>
      <template v-else>
        <div class="text-center loader-wrapper">
          <v-progress-circular
            class="my-5"
            :size="80"
            color="#008ffb"
            width="8"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
    </v-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      series: [],
      chartOptions: {
        colors: [],
        chart: {
          width: 400,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        labels: [],
        legend: {
          show: true,
          position: "left",
          formatter: function(val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  components: {
    apexchart: VueApexCharts,
  },
  watch: {
    is_content_loading() {
      this.assignValues();
    },
  },
  computed: {
    ...mapGetters({
      notification_analysis_data: "notification/getAnalysisData",
      is_loading: "notification/getIsLoading",
    }),
    donut_chart_data() {
      return this.notification_analysis_data.donut_chart_data;
    },
    is_content_loading() {
      return this.is_loading;
    },
  },
  created() {
    this.assignValues();
  },
  methods: {
    dotColor(color) {
      return `color: ${color}`;
    },
    assignValues() {
      // console.log("donut_chart_data",this.donut_chart_data);
      if (this.donut_chart_data) {
        this.series = [];
        this.chartOptions.labels = [];
        this.chartOptions.colors = [];
        this.donut_chart_data.forEach((item) => {
          this.series.push(item.total_notifications_count);
          this.chartOptions.colors.push(item.color);
          this.chartOptions.labels.push(item.topic_name);
        });
        // console.log("this.chartOptions.labels",this.chartOptions);
      }
    },
  },
};
</script>

<style scoped>
.topic-analysis-card {
  border-radius: 15px;
  height: inherit;
}
.topic-analysis-card-title {
  font-size: 20px;
  font-weight: 900;
  /* color: #6d7073; */
  color: #717477;
}
.topic-analysis-card-sub-title {
  font-size: 16px;
  font-weight: 700;
  /* color: #6d7073; */
  color: #717477;
}
.topic-analysis-card-list {
  font-size: 15px;
}
.topic-analysis-card-total-title {
  padding: 15px 0;
  font-size: 16px;
  font-weight: 700;
  /* color: #6d7073; */
  color: #717477;
}
.loader-wrapper {
  width: 100%;
}
</style>
